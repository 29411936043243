import { Component, OnInit, ViewChild } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../services/globals';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from '../../services/currency';
import { Utility } from '../../services/utility';
import { MatTable } from '@angular/material/table';
import { CurrentUser } from '../../services/user';
import { LogViewerComponent } from '../../common/log-viewer/log-viewer.component';

@Component({
    selector: 'gift-card-administration',
    templateUrl: './gift-card-administration.component.html',
    styleUrls: ['./gift-card-administration.component.scss']
})

export class GiftCardAdministrationComponent implements OnInit {
    giftCardId: any;
    giftCard: any | null = null;
    giftCardExpired: boolean = false;

    giftCardOrder: any = null;
    giftCardsInTheOrder: any[] = [];

    displayedColumns = ['code', 'amount', 'discountAmount', 'experation'];

    paramsSubscription: any;

    @ViewChild('giftCardsTable', { static: false }) giftCardsTable: MatTable<any>;


    constructor(private api: Api, private router: Router, private route: ActivatedRoute,
    private globals: Globals, public translate: TranslateService, private utility: Utility,
    public currency: Currency, public currentUser: CurrentUser) {

    }

    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.giftCardId = params['id'];
                this.giftCard = await this.api.client().get<any>(`/manage/gift-cards/${this.giftCardId}`);

                //featch giftCardOrder if giftCard has one
                if (this.giftCard.giftCardOrderId) {
                    this.giftCardOrder = await this.api.client().get<any>(`/gift-card-orders/${this.giftCard.giftCardOrderId}`);

                    //filter out current giftcard. No need to load it twice
                    let filtedIds = this.giftCardOrder.giftCardIds.filter((giftCardId: string) => giftCardId != this.giftCardId);

                    //go through all gift cards in the order and add them to the tempArray
                    for(let giftCardId of filtedIds) {
                        let otherGiftCard = await this.api.client().get<any>(`/manage/gift-cards/${giftCardId}`);
                        this.giftCardsInTheOrder.push(otherGiftCard);
                    }

                }

                //calculate if the gift card is expired or not
                this.giftCardExpired = moment(this.giftCard.expirationDate).isBefore(moment()) ? true : false;
            }
        });
    }

    openGiftCard() {
        window.open(`${this.globals.baseUrl}/api/public/clients/${this.globals.clientId}/gift-cards/${this.giftCardId}/view`, "_blank");
    }


    async save() {
        this.giftCard = await this.api.client().patch<any>(`/manage/gift-cards/${this.giftCardId}`, this.giftCard);
        window.location.reload();
    }

    giftCardValueDisabled(){
        if(this.currentUser.user.role == 'superadmin') {
            return false;
        }

        if(this.currentUser.user.role != 'manager'){
            return true;
        }

        if(this.globals.clientSettings && this.globals.clientSettings.enableCashRegisterIntegration){
            return true;
        }

        return false;
    }

    async OnDestroy() {
        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }
    }


}
