export type CountryCode = 'se' | 'no' | 'dk' | 'fi' | 'fr' | 'nl' | 'de' | 'ch' | 'ae' | 'es' 
| 'be' | 'gb' | 'nz' | 'eg' | 'pl' | 'in' | 'ie' | 'cz' | 'pt' | 'it' | 'at' | 'ko' | 'th';


export const availableCountries = [
    {
        name: "Sweden",
        code: "se"
    },
    {
        name: "Norway",
        code: "no"
    },
    {
        name: "Denmark",
        code: "dk"
    },
    {
        name: "Finland",
        code: "fi"
    },
    {
        name: "France",
        code: "fr"
    },
    {
        name: "Netherlands",
        code: "nl"
    },
    {
        name: "Germany",
        code: "de"
    },
    {
        name: "Switzerland",
        code: "ch"
    },
    {
        name: "United Arab Emirates",
        code: "ae"
    },
    {
        name: "Spain",
        code: "es"
    },
    {
        name: "New Zealand",
        code: "nz"
    },
    {
        name: "Belgium",
        code: "be"
    },
    {
        name: "United Kingdom",
        code: "gb"
    },
    {
        name: "Egypt",
        code: "eg"
    },
    {
        name: "Poland",
        code: "pl"
    },
    {
        name: "Saudi Arabia",
        code: "sa"
    },
    {
        name: "India",
        code: "in"
    },
    {
        name: "Ireland",
        code: "ie"
    },
    {
        name: "Czech Republic",
        code: "cz"
    },
    {
        name: "Portugal",
        code: "pt"
    },
    {
        name: "Italy",
        code: "it"
    },
    {
        name: "Austria",
        code: "at"
    },
    {
        name: "South Korea",
        code: "kr"
    },
    {
        name: "Thailand",
        code: "th"
    }
];
