import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentUser } from '../../services/user';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { i } from '@fullcalendar/resource/internal-common';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'log-viewer',
    templateUrl: './log-viewer.component.html',

})

export class LogViewerComponent implements OnInit {
    @Input()
    log: any;

    displayedColumns = ['created', 'message'];

    constructor(private router: Router, private route: ActivatedRoute, public currentUser: CurrentUser, public translate: TranslateService) {}

    ngOnInit(): void {

        //add superadmin column for superadmins to see what is visible to the client and what is not
        if(this.currentUser.user && this.currentUser.user.role == 'superadmin'){
            this.displayedColumns = ['superadmin','created','message'];
        }

        if (this.log){
            this.log.sort((a: any, b: any) => moment(b.created).valueOf() - moment(a.created).valueOf());
        }
    }

}
